<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
    >
      <!-- Filter -->
      <b-row>

        <b-col
          md="3"
          lg="3"
          xs="12"
        >
          <v-select
            v-model="selectedStatusId"
            :options="statusOption"
            :reduce="(item) => item.value"
            label="name"
            placeholder="Select Status"
            class="mb-1 custom-font"
            @input="loadItems()"
          >
          </v-select>
        </b-col>

        <b-col
          md="3"
          lg="3"
          xs="12"
        >
          <v-select
            v-model="selectedPackageId"
            :options="packages"
            :reduce="(item) => item.id"
            label="title"
            placeholder="Select Packages"
            class="mb-1 custom-font"
            @input="loadItems()"
          >
          </v-select>
        </b-col>
        <b-col
          md="6"
          lg="6"
          xs="12"
        >
        <b-form-input
          v-model.lazy="searchTerm"
          placeholder="Search Full Name / Company Name..."
          type="text"
          class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          @keyup="onSearch"
        />
        </b-col>
      </b-row>      
    </b-card-actions>
    
  <b-card>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :total-rows="totalRecords"
      :is-loading.sync="isLoading"
      :rows="rows"
      :sort-options="{
        enabled: false,
        multipleColumns: true,
        initialSortBy: [
          { field: 'id', type: 'desc' },
          { field: 'name', type: 'desc' },
          { field: 'created_at', type: 'desc' },
        ],
      }"
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- head -->
        <span class="font-weight-bold" v-if="props.column.field === 'format_name'">
          {{
            props?.row?.name
          }}
        </span>

        <span v-if="props.column.field === 'format_package_price'">
          BDT {{ props.row?.package_price }}
        </span>

        <span v-if="props.column.field === 'format_payment_method'">
            <b-badge :variant="getPaymentMethodVariant(props.row.payment_method_string)">
                {{ getPaymentMethodText(props.row.payment_method_string) }}
            </b-badge>
        </span>

        <span v-if="props.column.field === 'format_message'">
          <b-button v-if="props.row?.message"
              v-b-tooltip.hover
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              :title='props.row?.message'
          >
            <feather-icon class="btn-icon" icon="InfoIcon" />
          </b-button>
        </span>

        <span v-if="props.column.field === 'format_status'">
            <b-badge :variant="getStatusVariant(props.row.status_string)">
                {{ getStatusText(props.row.status_string) }}
            </b-badge>
        </span>

        <span v-if="props.column.field === 'action'">
          <span v-if="props.row.status == 1">
              <feather-icon
                  v-b-tooltip.hover
                  icon="CheckCircleIcon"
                  class="mr-50 custom-icon cursor-pointer text-warning"
                  title="Approve Now"
                  size="18"
                  @click="approveCompany(props.row)"
              />
              <feather-icon v-if="props.row.package_id === null"
                v-b-tooltip.hover
                icon="PackageIcon"
                class="mr-50 custom-icon cursor-pointer text-primary"
                title="Assign Package"
                size="18"
                @click="showPackageModal(props.row)"
              />
          </span>
          <span v-else-if="props.row.status == 2">
              <feather-icon
                  v-b-tooltip.hover
                  icon="CheckCircleIcon"
                  class="mr-50 custom-icon cursor-pointer text-success"
                  title="Approved"
                  size="18"
              />
          </span>
          <span v-else>
              <feather-icon
                  v-b-tooltip.hover
                  icon="XCircleIcon"
                  class="mr-50 custom-icon cursor-pointer text-danger"
                  title="Pending Approval"
                  size="18"
              />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '25', '50', '100', '500']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
        id="modal-package-model"
        centered
        title="Company Package Assign"
        hide-footer
        no-close-on-backdrop
        @hidden="hiddenPackageModal"
      >
        <validation-observer ref="companyPaymentHistory">
          <b-form @submit.prevent="validCompanyPacakageAssign">
            <!-- name -->
            <b-form-group
              label="Select Package"
              label-for="package_id"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                name="package_id"
                vid="package_id"
              >
              <v-select
                v-model="selectedPackageIdForCompany"
                :options="packageOptions"
                :reduce="(item) => item.id"
                label="title"
                placeholder="Select Packages"
                class="mb-1 custom-font"
              ></v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- loading button -->
            <template v-if="isCompanyPackageFormSubmitLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="float-right"
                variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>

      </b-modal>
  </b-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormDatepicker,
  BLink, VBTooltip, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserCard from '@/layouts/components/UserCard.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  name: 'CompanyRequestView',
  components: {
    BFormTextarea,
    BCol,
    BRow,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BLink,
    UserCard,
    BCardActions,
    BFormDatepicker,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // filter
      selectedStatusId: '',
      selectedPackageId: '',
      packages: [],
      
      statusOption: [
        { name: 'Pending', value: 1 },
        { name: 'Active', value: 2},
      ],
      pageLength: 10,
      columns: [
        {
          label: 'Full Name',
          field: 'full_name',
        },
        {
          label: 'Company Name',
          field: 'format_name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Current Package',
          field: 'package_title',
        },
        {
          label: 'Package Price',
          field: 'format_package_price',
        },
        {
          label: 'Message',
          field: 'format_message',
        },
        {
          label: 'Request Date',
          field: 'created_at',
        },
        {
          label: 'Status',
          field: 'format_status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: 'id', type: 'desc' },
          { field: 'name', type: 'desc' },
        ],
        page: 1,
        perPage: 10,
      },
      isCompanyPackageFormSubmitLoading: false,
      company_id: "",
      packageOptions: [],
      selectedPackageIdForCompany: "",
    }
  },


  methods: {
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems()
      }, 1000)
    },
    showPackageModal(data) {
      this.company_id = data.id;
      this.$bvModal.show("modal-package-model");
    },

    hiddenPackageModal() {
      this.$bvModal.hide("modal-package-model");
      this.resetPackageModal();
    },

    resetPackageModal() {
      this.company_id = "",
      this.package_id = "";
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    async getSubscriptionPricingItems() {
      return await this.$api.get("api/super-admin/subscription-pricing", {
        params: {
          payment_method: 3,
        }
      });
    },
    async validCompanyPacakageAssign() {
      this.$refs.companyPaymentHistory.validate().then(async (success) => {
        if (success) {
          try { 
            
              this.isCompanyPackageFormSubmitLoading = true;
              let formData = new FormData();
              formData.append("company_id", this.company_id);
              formData.append("package_id", this.selectedPackageIdForCompany);
              
              const response = await this.$api.post(
                "/api/super-admin/company-package-assign",
                formData,
              );
              
              this.isCompanyPackageFormSubmitLoading = false;
              this.hiddenPackageModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: response?.data?.message,
                },
              });
          } catch (error) {
            this.isCompanyPackageFormSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.companyValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    async getCompanies(params) {
      return await this.$api.get('api/super-admin/company-request-list', {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          status: params.status,
          package: params.package,
        },
      })
    },

    async loadItems() {
      try {
        const [companies] = await Promise.all([
          this.getCompanies({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            status: this.selectedStatusId,
            package: this.selectedPackageId,
          }),
        ])

        const data = companies?.data?.data
        const response = await this.getSubscriptionPricingItems();
        
        const preparedData = response.data.map(item => ({
          id: item.id,
          title: item.title
        }));
        this.packageOptions = preparedData
        const meta = companies?.data?.meta
        
        this.packages = preparedData

        this.totalRecords = meta?.pagination?.total
        this.rows = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async getApproveCompany(params) {
      return await this.$api.get("api/super-admin/company-request-approve", {
        params: {
          id: params.company_id,
        },
      });
    },
    async approveCompany(row) {
      this.$swal({
        title: 'Warning!',
        text: `Are You Sure You Want To Confirm This Company?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            const response = await this.getApproveCompany({
                  company_id: row.id,
                });
                this.loadItems()
                if (response.status) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Success",
                      icon: "BellIcon",
                      variant: "success",
                      text: response.data.message,
                    },
                  });

                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: response.data.message,
                    },
                  });
                }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          }
        }
      })
    },
    getStatusVariant(status) {
        switch(status) {
          case 'active':
            return 'light-success'; // Green for completion
          case 'pending':
              return 'light-warning';
            default:
                return 'light-secondary'; // default color
        }
    },
    getStatusText(status) {
        switch(status) {
            case 'active':
                return 'Active';
            case 'pending':
                return 'Pending';
            default:
                return 'Unknown';
        }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-flatpicker';
</style>
